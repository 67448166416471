/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.RadioInput {
  display: table;
  cursor: pointer;
  width: 100%;
}
.RadioInput__inner {
  display: table;
  cursor: pointer;
  width: 100%;
  margin-left: 1rem;
}
.RadioInput label .label {
  width: 100%;
}
.RadioInput label input {
  width: auto !important;
  display: table-cell !important;
}
label .RadioInput__option-text {
  padding-left: 1rem;
  display: table-cell;
  font-size: 1.4rem;
  font-weight: 300;
  color: #2d272d;
}
