/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.CancelPage {
  background-color: #f2eee5;
  position: relative;
  color: #745a9e;
  overflow: hidden;
}
.CancelPage__header {
  text-align: center;
  padding-bottom: 2rem;
  padding-top: 2rem;
  font-size: 3.2rem;
  font-weight: bold;
}
.CancelPage__subheader {
  padding-bottom: 2rem;
  font-size: 1.6rem;
  font-weight: 600;
}
.CancelPage__step {
  text-align: center;
  font-size: 1.8rem;
  max-width: 60rem;
  margin: auto;
  background-color: white;
  border-radius: 1rem;
  padding: 2rem;
  position: relative;
  border-bottom: 0.7rem solid #e6e0d0;
  min-height: 34rem;
}
.CancelPage__icon {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.CancelPage__progress {
  margin: auto;
  padding-top: 2rem;
  font-size: 1.6rem;
  color: #745a9e;
}
.CancelPage__prog-subtitle {
  padding-bottom: 2rem;
}
.CancelPage__cta {
  margin-top: 5rem;
}
.CancelPage__footer {
  display: table;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.CancelPage__footer-cell {
  display: table-cell;
  width: 33%;
  text-align: center;
  font-size: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.CancelPage__footer-cell-link {
  text-decoration: underline;
  cursor: pointer;
  display: inline;
}
.CancelPage__footer-cell-link:hover {
  text-decoration: underline;
  color: #745a9e;
}
.CancelPage__footer-cell:nth-child(1) {
  padding-left: 2rem;
}
.CancelPage__footer-cell:nth-child(3) {
  padding-right: 2rem;
}
.CancelPage__incentive {
  max-width: 80rem;
  color: #fff;
  background-color: #745a9e;
  text-align: left;
  padding: 2rem;
  margin: auto;
  margin-bottom: 2rem;
  border-radius: 1rem;
  cursor: pointer;
}
.CancelPage__incentive-header {
  font-size: 7rem;
}
.CancelPage__confirm {
  font-size: 3rem;
  font-weight: 600;
  padding-bottom: 4rem;
  padding-top: 4rem;
}
.CancelPage__remain-active {
  padding-top: 4rem;
  font-size: 1.8rem;
  font-weight: 600;
}
.CancelPage__date {
  font-weight: 600;
}
.CancelPage__directional-link {
  text-align: right;
}
.CancelPage__directional-link-next {
  text-align: left;
}
.CancelPage__step-meter {
  width: 10%;
}
