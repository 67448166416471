/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.AnimatedCard {
  display: inline-block;
  width: 50%;
  position: relative;
  cursor: pointer;
}
@media (max-width: 768px) {
  .AnimatedCard {
    width: 100%;
  }
}
.AnimatedCard__background {
  position: absolute;
  top: -24rem;
  left: 0;
  transition: all 0.3s;
  transform: scale(1.3);
  z-index: 100;
}
.AnimatedCard__title {
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  position: absolute;
  opacity: 1;
  z-index: 100;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 767px) {
  .AnimatedCard__title {
    background: none;
  }
}
.AnimatedCard__title--hidden {
  top: 10%;
  opacity: 0;
}
.AnimationCard__loading-wheel {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}
